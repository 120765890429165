import React, {useEffect} from 'react';
import ReactGA from 'react-ga'
import './App.css';

const gaId = "UA-36738577-2";

function App() {
  useEffect(() => {
    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname);
  })

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Aerto
        </h1>
        {/* <p>
          Coming Soon
        </p> */}
      </header>
    </div>
  ); 
}

export default App;
